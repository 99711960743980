import { Heading } from '@troon/ui';
import type { JSX, ParentProps } from 'solid-js';

type Props = ParentProps<{
	title: JSX.Element;
	subtext?: JSX.Element;
}>;

export function PageHeader(props: Props) {
	return (
		<header class="grid grid-cols-12 items-center gap-4 lg:gap-8">
			<div class={'col-span-12 flex w-full flex-col gap-4 lg:col-span-8'}>
				<Heading as="h1">{props.title}</Heading>
				<div class="text-base text-neutral-800 md:text-lg">{props.subtext}</div>
			</div>

			<div class="col-span-12 flex justify-end lg:col-span-4">{props.children}</div>
		</header>
	);
}
